/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import Navbar from "./navBar"

import logoNovus from "../images/novus.png"


const Layout = ({ children, pageInfo, location }) => (

  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        allNodePage {
    edges {
      node {
        title
      }
    }
  }
      }
    `}

    render={data => (

      <>
        <Container fluid className="px-0 main">
          <Row className="justify-content-center">
            <Col>
             
                <div className="container d-flex h-100 align-items-center">
                  <div className="mx-auto text-center">
                    {/* <h1 id="texttitleLayout" className="mx-auto my-0 cssanimation sequence fadeInBottom">{page(location)}</h1> */}
                  </div>
                </div>
            </Col>
          </Row>
          <Navbar pageInfo={pageInfo} />

              <Container className="mt-5 body-container" style={{padding: `0 3% 0 3%`}}>
                
               
                <br />
                <main>{children}</main>
              </Container>
        </Container>
        <Container fluid className="px-0">
          <Row>
            <Col className="footer-col">
              <footer>
              <span>
                <img src={logoNovus} alt="logoNovus" style={{ maxHeight: `75px` }} />
                  <br />
                   {new Date().getFullYear()}, <a href="https://novus.itesm.mx/" rel="noopener noreferrer" className="link-no-style" style={{ color: `#fff` }} target="_blank"> Novus </a> - OpenResearchLab <br />
                  <img src="https://static.wixstatic.com/media/446d2f_82746ead81a447a4b94ec6572070b933~mv2.png/v1/fill/w_114,h_38,al_c,lg_1,q_85/cc.webp" alt="cc.png" style={{ width: `91px`, height: `30px` }}></img>
                  <p>Este obra está bajo una <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer noopener">licencia de Creative Commons Reconocimiento-NoComercial-CompartirIgual 4.0 Internacional</a></p>

                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default Layout
